import type {ReactNode} from 'react';
import React from 'react';
import Head from 'next/head';

type Props = {
	children?: ReactNode;
	title?: string;
};

const Layout: React.FC<Props> = ({children, title = 'Home'}) => (
	<>
		<Head>
			<title>{title} - Partner Portal</title>
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
		</Head>
		{children}
	</>
);

export default Layout;
